import React, { Component } from "react";

import Logo from "../../assets/logo.png";
import Input from "../../UI_Elements/Form/Input";

import "./sass/Signup.scss";

import { withRouter } from "react-router";
import { Link } from "react-router-dom";

//Assets
import { MdClose } from "react-icons/md";

import axios from "axios";

import LOG_IN_USER from "../../inc/LOG_IN_USER";

import moment from "moment-timezone";

import GoogleOAuth from "../../inc/GoogleOAuth";

import Preloader from "../../UI_Elements/Preloader";

import Response from "../../UI_Elements/Response";
import showResponse from "../../UI_Elements/Response/showResponse";

class Signup extends Component {
  constructor() {
    super();

    this.state = {
      //Errors
      errorName: "",
      errorSurname: "",
      errorEmail: "",
      errorPassword: "",

      formSent: false,

      GoogleOAuthState: 1,
    };

    this.name = React.createRef();
    this.surname = React.createRef();
    this.email = React.createRef();
    this.password = React.createRef();

    this.REGISTER_USER = this.REGISTER_USER.bind(this);
  }

  REGISTER_USER(e) {
    e.preventDefault();

    this.setState({ formSent: true });

    //Validate
    let nameString = this.name.current.state.inputValue;
    let surnameString = this.surname.current.state.inputValue;
    let emailString = this.email.current.state.inputValue;
    let passwordString = this.password.current.state.inputValue;

    //const { errorEmail, errorPassword, errorName, errorSurname } = this.state;

    let nameIsValid = RegExp('[A-Z][a-zA-Z][^#&<>"~;$^%{}?]{1,10}$', "g").test(
      nameString
    );
    let surnameIsValid = RegExp(
      '[A-Z][a-zA-Z][^#&<>"~;$^%{}?]{1,10}$',
      "g"
    ).test(surnameString);
    let emailIsValid = RegExp(
      "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}",
      "g"
    ).test(emailString);
    let passwordIsValid = RegExp("(?=.{8,})", "g").test(passwordString);

    //name
    if (!nameIsValid) {
      this.setState({
        errorName:
          "The name must begin with a capital letter and must be at least 2 characters and a maximum of 10",
        formSent: false,
      });
    } else this.setState({ errorName: "" });

    //surname
    if (!surnameIsValid) {
      this.setState({
        errorSurname:
          "The surname must begin with a capital letter and must be at least 3 characters and a maximum of 10",
        formSent: false,
      });
    } else this.setState({ errorSurname: "" });

    //email
    if (!emailIsValid) {
      this.setState({ errorEmail: "Email is not valid", formSent: false });
    } else this.setState({ errorEmail: "" });

    //password
    if (!passwordIsValid) {
      this.setState({
        errorPassword: "The password must be 8 characters or longer",
        formSent: false,
      });
    } else this.setState({ errorPassword: "" });

    //Check errors
    if (nameIsValid && surnameIsValid && passwordIsValid && emailIsValid) {
      axios
        .post("/api/signup", {
          name: nameString,
          surname: surnameString,
          email: emailString,
          password: passwordString,
          currentTimeZone: moment.tz.guess(),
        })
        .then((res) => {
          this.setState({ formSent: false });

          LOG_IN_USER(res.data);
          this.props.history.push("/");
        })
        .catch((error) => {
          this.setState({ formSent: false });
          showResponse("SignupError", error.response.data);
        });
    } else return;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const {
      errorEmail,
      errorPassword,
      errorName,
      errorSurname,
      GoogleOAuthState,
      formSent,
    } = this.state;

    if (GoogleOAuthState == 0) return <Preloader page />;

    return (
      <div id="Signup">
        <Response
          id="SignupError"
          type="error"
          title="Something went wrong"
          description=""
          btnText="Try again"
          btnAction="close"
        />

        <section>
          <div className="content">
            <Link className="back" to="/">
              <MdClose size="35" />
            </Link>

            <div>
              <Link to="/">
                <img width="220" src={Logo} alt="" />
              </Link>
              <h2 className="trl signup title">Get started, it’s free.</h2>

              <form className="simple" onSubmit={this.REGISTER_USER}>
                <Input
                  id="signupName"
                  error={errorName}
                  ref={this.name}
                  name="name"
                  placeholder="Name"
                />
                <Input
                  id="signupSurname"
                  error={errorSurname}
                  ref={this.surname}
                  name="surname"
                  placeholder="Surname"
                />
                <Input
                  id="signupEmail"
                  type="email"
                  error={errorEmail}
                  ref={this.email}
                  name="email"
                  placeholder="Email"
                />
                <Input
                  id="signupPassword"
                  type="password"
                  error={errorPassword}
                  ref={this.password}
                  name="password"
                  placeholder="Password"
                />
                {formSent ? (
                  <Preloader />
                ) : (
                  <button className="btn large trl signup btnSignup">
                    signup
                  </button>
                )}
              </form>

              <div className="footer">
                <p className="trl signup orSignUpWith">— Or sign up with —</p>

                <GoogleOAuth
                  label="Sign up with Google"
                  startOAuth={() => this.setState({ GoogleOAuthState: 0 })}
                  success={() => this.props.history.push("/my-listings")}
                />

                <p>
                  <span className="trl signup alreadyAccount">
                    Already have an account?
                  </span>{" "}
                  <Link className="primaryLink trl signup logIn" to="/login">
                    Log In
                  </Link>
                </p>
              </div>

              <p className="copy">
                <span className="trl signup copyRight1">
                  By clicking sign up you agree to our
                </span>{" "}
                <a
                  className="primaryLink trl signup copyRight3"
                  target="_blank"
                  href="/privacy-policy"
                >
                  Privacy Policy
                </a>
                .
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(Signup);
